import React, { useContext, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-link";
import classNames from "classnames";

import {
  GlobalDateContext,
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider";
import ArrowRight from "../../svg/arrow-right.svg";
import "./simple-registration.scss";

export const SimpleRegistration = () => {
  const [datesFrom, setDatesFrom] = useState({ date: null, from: null });
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const pageQuery = useStaticQuery(
    graphql`
      query SimpleRegistrationQuery {
        allFile(filter: { relativePath: { eq: "registration-data.md" } }) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  dates {
                    date
                    from
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const registrationDatesData =
    pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter.dates;
  const datesSliced = registrationDatesData.slice(0, 6);

  const handleClick = () => {
    state.from = datesFrom.from;
    state.date = datesFrom.date;
    dispatch({ type: "SET_DATES" });
    navigate("/registracija-siuntos/");
  };

  return (
    <div className="schedule">
      <h3 className="schedule__title">Artimiausi reisai:</h3>
      <ul className="schedule__list">
        {datesSliced &&
          datesSliced.map((singleDate, index) => (
            <li className="schedule__list-item">
              <button
                className={classNames("schedule__btn", {
                  "schedule__btn--active": singleDate === datesFrom,
                })}
                onClick={() => setDatesFrom(singleDate)}
              >
                <span className="schedule__date">{singleDate.date}</span>
                <div className="schedule__from">
                  <span className="schedule__label">
                    {singleDate.from === "LT" ? "Iš Lietuvos" : "Iš Anglijos"}
                  </span>
                  <span className="schedule__country">{singleDate.from}</span>
                </div>
              </button>
            </li>
          ))}
      </ul>
      <button className="schedule__btn-cta" onClick={() => handleClick()}>
        Registruoti siuntą
        <ArrowRight />
      </button>
    </div>
  );
};
