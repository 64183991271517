import React from "react";

import IconClock from "../../svg/clock.svg";
import IconDoors from "../../svg/doors.svg";
import IconShield from "../../svg/shield.svg";
import IconPeople from "../../svg/people.svg";
import "./why-us.scss";

export const WhyUs = () => {
  return (
    <div className="why-us">
      <div className="why-us__frame">
        <div className="why-us__row">
          <div className="why-us__column why-us__column--left">
            <h2 className="why-us__title">
              Greitas ir patikimas siuntų pervežimas
            </h2>
            <p className="why-us__desc">
              Daugelį metų dirbame su siuntų pervežimo paslaugomis Lietuvoje bei
              kitose Europos Sąjungos šalyse. Ilgametė patirtis ir profesionalus
              požiūris mums leidžia užtikrinti išskirtinį krovinių saugumą,
              pristatymo greitį už optimalią kainą. Visos mūsų gabenamos siuntos
              yra apdrausti, todėl galite būti užtikrinti dėl jų saugumo įvykus
              nenumatytoms aplinkybėms.
            </p>
          </div>
          <div className="why-us__column why-us__column--right">
            <h2 className="why-us__title">Kodėl verta rinktis mus?</h2>
            <ul className="why-us__list">
              <li>Išskirtinis požiūris į siuntų saugumą.</li>
              <li>
                Siuntos paimamos iš siuntėjo namų ir pristatomos iki gavėjo
                durų.
              </li>
              <li>
                Gabename nestandartinio dydžio siuntas: nuo pačių mažiausių iki
                stambių.
              </li>
              <li>Siūlome patrauklias ir prieinamas kainas.</li>
              <li>
                Pervežimus atlieka profesionalių ir patyrusių vairuotojų
                komanda.
              </li>
            </ul>
          </div>
        </div>
        <div className="bar">
          <div className="bar__column">
            <div className="bar__icon">
              <IconPeople />
            </div>
            <span className="bar__text">Patikima komanda</span>
          </div>
          <div className="bar__column">
            <div className="bar__icon">
              <IconClock />
            </div>
            <span className="bar__text">Greita pristatymas</span>
          </div>
          <div className="bar__column">
            <div className="bar__icon">
              <IconShield />
            </div>
            <span className="bar__text">Saugus pervežimas</span>
          </div>
          <div className="bar__column">
            <div className="bar__icon">
              <IconDoors />
            </div>
            <span className="bar__text">Nuo durų iki durų</span>
          </div>
        </div>
      </div>
    </div>
  );
};
