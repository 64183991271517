import React from "react";

import IconArrow from "../../svg/arrow-right.svg";
import IconDownload from "../../svg/download.svg";
import downloadPdf from "../../../static/declaration-forms-CN22.pdf";
import "./declaration.scss";

export const Declaration = () => {
  return (
    <div className="declaration">
      <div className="declaration__frame">
        <div className="declaration__wrapper">
          <IconDownload className="declaration__icon" />
          <h2 className="declaration__title">Siuntos deklaracija</h2>
          <p className="declaration__desc">
            Nuo 2021 m. Sausio 1 d. siunčiant siuntas į JK ir iš UK į Lietuvą
            yra privaloma pildyti pašto muitinės deklaraciją kiekvienai siuntai.
            Atsispausdinkite CN22 deklaracijos formą ir užpildę pridėkite ją
            prie siuntos. Jei negalite to padaryti mūsų varuotojai duos
            užpildyti CN22 formą atvažiavę pasiimti siuntos.
          </p>
          <a className="declaration__btn" href={downloadPdf}>
            <IconArrow />
            Parsisiųsti muitinės <br />
            deklaracijos formą
          </a>
        </div>
      </div>
    </div>
  );
};
