import React from "react";

import imgSrc from "../../scss/assets/map-bg.jpg";
import "./service-area.scss";

export const ServiceArea = () => {
  return (
    <div className="service-area">
      <div className="service-area__frame">
        <div className="service-area__row">
          <div className="service-area__column-content">
            <h2>Siuntų pervežimo maršrutas Anglijoje</h2>
            <p>
              Anglijoje krovinius vežame į šiuos miestus ir jų apylinkes. Jei
              jūsų adresas randasi už mūsų maršruto ribų, susisiekite su mumis
              ir mes rasim kaip jums padėti.
            </p>
            <ul>
              <li>Dover</li>
              <li>Maidstone</li>
              <li>Gravesend</li>
              <li>London</li>
              <li>Luton</li>
              <li>Milton Keynnes</li>
              <li>Bedford</li>
              <li>Northampton</li>
              <li>Leicester</li>
              <li>Kettering</li>
              <li>Corby</li>
              <li>Pyterborough</li>
              <li>Wisbech</li>
              <li>Kings lynn</li>
              <li>Boston</li>
              <li>Spalding</li>
              <li>Nortwich</li>
              <li>Birmingham</li>
              <li>Coventry</li>
              <li>Thetford</li>
              <li>Royal</li>
              <li>Leamington Spa</li>
              <li>Bury St</li>
              <li>Edmunds</li>
              <li>Oxford</li>
              <li>Derby</li>
              <li>Nottingham</li>
              <li>Birmingham</li>
              <li>Worcester</li>
              <li>Ipswich</li>
            </ul>
          </div>
          <div className="service-area__column-map">
            <iframe
              width="500"
              height="300"
              src="https://api.maptiler.com/maps/abbc8e71-c745-4054-86f5-ff4c059c767b/?key=wbdq8yZwAsgwymA60VxE#7.01/52.123/-0.445"
            />
            {/* <div className="service-area__img-wrapper">
              <img src={imgSrc} alt="Žilvino reisai Lietuva - Anglija" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
