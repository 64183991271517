import React from "react";

import "./hero-banner.scss";
import { SimpleRegistration } from "../simpleRegistration/SimpleRegistration";
import IconArrows from "../../svg/arrows.svg";
import FlagUk from "../../svg/flag-uk.svg";
import FlagLt from "../../svg/flag-lt.svg";
import BackgroundSvg from "../../svg/wings-bg.svg";

export const HeroBanner = () => {
  return (
    <div className="hero">
      <BackgroundSvg className="hero__bg" />
      <div className="hero__frame">
        <div className="hero__wrapper">
          <div className="hero__column-content">
            <IconArrows className="hero__arrows" />
            <h1 className="hero__title">Siuntų ir krovinių <br />pervežimas</h1>
            <p className="hero__desc">Lietuva - Anglija - Lietuva</p>
            <div className="hero__buttons">
              <a className="hero__btn" href="tel:+37062518777">
                <FlagLt className="hero__flag" />
                <span className="hero__text">+370 625 187 77</span>
              </a>
              <a className="hero__btn" href="tel:+447872137294">
                <FlagUk className="hero__flag" />
                <span className="hero__text">+44 78 72 1372 94</span>
              </a>
            </div>
          </div>
          <div className="hero__column-form">
            <SimpleRegistration />
          </div>
        </div>
      </div>
    </div>
  );
};
