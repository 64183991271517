import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/mainLayout/MainLayout.jsx";
import { HeroBanner } from "../components/heroBanner/HeroBanner.jsx";
import { Prices } from "../components/prices/Prices.jsx";
import { WhyUs } from "../components/whyUs/WhyUs.jsx";
import { ServiceArea } from "../components/serviceArea/ServiceArea.jsx";
import { Declaration } from "../components/declaration/Declaration.jsx";
import { Feedback } from "../components/feedback/Feedback.jsx";

const IndexPageV2 = ({ data }) => {
  return (
    <Layout
      title="Siuntos į Angliją. Saugus siuntų ir krovinių pervežimas"
      description="Daugelį metų dirbame su siuntų pervežimo paslaugomis Lietuvoje bei kitose Europos Sąjungos šalyse. Ilgametė patirtis ir profesionalus požiūris mums leidžia užtikrinti išskirtinį krovinių saugumą, pristatymo greitį už optimalią kainą."
    >
      <HeroBanner />
      <Prices />
      <WhyUs />
      <ServiceArea />
      <Declaration />
      <Feedback />
    </Layout>
  );
};

export default IndexPageV2;

export const pageQuery = graphql`
  query IndexPageV2Template {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`;
