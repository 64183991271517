import React from "react";

import IconStars from "../../svg/stars.svg";
import "./feedback.scss";
import { FeedbackForm } from "../feedbackForm/FeedbackForm";

export const Feedback = () => {
  return (
    <div className="feedback">
      <div className="feedback__frame">
        <div className="feedback__row">
          <div className="feedback__column feedback__column--left">
            <h2 className="feedback__title">Mūsų klientų atsiliepimai</h2>
            <div className="feedback__list">
              <div className="feedback__item">
                <strong>Edita</strong>
                <IconStars />
                <p>
                  10 metų siunčiame siuntinukus su Žilvino Reisai. Rekomenduoju.
                  Niekada nenuvylė.
                </p>
              </div>
              <div className="feedback__item">
                <strong>Milda</strong>
                <IconStars />
                <p>
                  Patys geriausi, patikimiausi!. Jau daug metų siuntos keliauja
                  tik per šią kompaniją, niekada jokių nesklandumų.
                </p>
              </div>
              <div className="feedback__item">
                <strong>Monika</strong>
                <IconStars />
                <p>
                  Daug metų siuntos keliauja per šitą kompaniją. Niekada nebuvo
                  jokių problemų, visada laiku o svarbiausia labai patikimi.
                  Linkiu ir toliau išlikti tokiais gerais!
                </p>
              </div>
              <div className="feedback__item">
                <strong>Angelė</strong>
                <IconStars />
                <p>
                  Jūs patys geriausi ir patikimiausi, per daug metų niekada
                  nenuvylėte.
                </p>
              </div>
            </div>
          </div>
          <div className="feedback__column feedback__column--right">
            <h2 className="feedback__title">Mums svarbi jūsų nuomonė</h2>
            <p className="feedback__desc">
              Palikite atsiliepimą, kad mes galėtume augti ir tobulėti, teikti
              tokias paslaugas, kurios reikalingos jums.
            </p>
            <div className="feedback__form-wrapper">
              <FeedbackForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
